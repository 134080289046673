export const names = [
    'Ver***',
    'enef***',
    'Yaiko3***',
    'nia72***',
    'Eriak***',
    'Ma***',
    'panec***',
    'hanorgil***',
    'Aro***',
    'stur***',
    'inelen***',
    'Mia12***',
];