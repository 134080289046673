import { createApp } from "vue";
import App from "./App.vue";
import routes from "./routes";
import store from "@/store";
import Vue3Autocounter from "vue3-autocounter";
import VueToastify from "vue-toastify";
import AosVue from "aos-vue";
import Vue3Marquee from "vue3-marquee";
import AOS from "aos";
import VueLazyload from "vue-lazyload";
import Vue3Odometer from "vue3-odometer";
import "odometer/themes/odometer-theme-default.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

//swiper
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

//aos
import "aos/dist/aos.css";

//i18n
import i18n from "@/locales";

//

import "sweetalert2/src/sweetalert2.scss";

const vuetify = createVuetify({
  components,
  directives,
});

export const app = createApp(App);
const loadimage = require("@/assets/applecasino/gifs/loading-image-2.gif");

app.config.errorHandler = (err, instance, info) => {
  // Handle the error globally
  console.error("Global error:", err);
  console.log("Vue instance:", instance);
  console.log("Error info:", info);

  // Add code for UI notifications, reporting or other error handling logic
};

app
  .use(routes)
  .use(store)
  .use(VueToastify)
  .use(i18n)
  .use(AosVue)
  .use(vuetify)
  .use(Vue3Marquee)
  .use(Vue3Odometer)
  .use(VueLazyload, {
    // Tùy chọn của bạn ở đây, ví dụ:
    preLoad: 1.3, // Tải hình ảnh trước khi nó xuất hiện 1.3 lần trong trường nhìn
    error: "path_to_error_image.jpg", // Hình ảnh hiển thị nếu xảy ra lỗi khi tải
    loading: loadimage, // Hình ảnh hiển thị trong quá trình tải
    attempt: 1, // Số lần thử tải lại hình ảnh nếu có lỗi
  })
  .use(
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      anchorPlacement: "center-bottom", // defines which position of the element regarding to window should trigger the animation
    })
  )
  .component("vue3-autocounter", Vue3Autocounter)
  .mount("#app");
