import { getBankInfoRequest, getGCashBankInfoRequest } from "@/api/getBankInfo/request";
// import store from "@/store";
// import { computed } from "vue";

const state = {
    listBankInfo: [],
    listGCashBankInfo: [],
    listVnBankInfo: []
};

const getters = {
    getBankInfo: (state) => {
        return state.listBankInfo;
    },
    getGCashBankInfo: (state) => {
        return state.listGCashBankInfo;
    },
    getVnBankInfo: (state) => {
        return state.listVnBankInfo;
    },
};

const mutations = {
    handleUpdateBankInfo(state, payload) {
        state.listBankInfo = payload;
    },
    handleUpdateGCashBankInfo(state, payload) {
        state.listGCashBankInfo = payload;
    },
    handleUpdateVnBankInfo(state, payload) {
        state.listVnBankInfo = payload;
    },
};

const actions = {
    async getBankInfoAction({ commit }) {
        const res = await getBankInfoRequest();
        if (res?.data) {
            commit("handleUpdateBankInfo", res.data);
        } else {
            console.log(res?.message);
        }
    },
    async getGCashBankInfoAction({ commit }) {
        const res = await getBankInfoRequest();
        if (res?.data) {
            commit("handleUpdateBankInfo", res.data);
        } else {
            console.log(res?.message);
        }
    },
    async getVnBankInfoAction({ commit }) {
        const res = await getBankInfoRequest();
        if (res?.data) {
            commit("handleUpdateVnBankInfo", res.data);
        } else {
            console.log(res?.message);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
