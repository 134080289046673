import axios from "axios";
import { BASE_API_URL } from "@/const";

const SSRRequest = axios.create({
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
    withCredentials: true,
    baseURL: BASE_API_URL,
    timeout: 60000,
});

SSRRequest.interceptors.response.use(
    (res) => {
        if (
            (res.data?.is_logined == true ||
                (res.data?.is_success == true && res.data?.error_code == 0)) &&
            res.config?.$useStore
        ) {
            res.config.$useStore(res.data, res);
        }

        return res.data;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
        }

        return Promise.reject(error.response.data);
    }
);

export { SSRRequest };
