const state = {
  toggleShow: {
    login: false,
    signUp: false,
    personal: false,
    notice: false,
    rules: false,
    partnership: false,
    loading: false,
    betting: false,
    betting1: false,
    pickBank: false,
    event: false,
    faq: false,
    deposit: false,
    withdraw: false,
    coupon: false,
    noticeEventFAQ: false,
    betrule: false,
    serviceCenter: false,
    message: false,
    casino: false,
    createQuery: false,
    slot: false,
    sport: false,
    services: false,
    skywind: false,
  },
  confirmDialog: null,
  isShowModal: false,
  isShowModalGame: false,
};

const getters = {};

const mutations = {
  showConfirm(state, payload) {
    const { title, message, callback } = payload;
    state.confirmDialog = { title, message, callback };
  },
  closeConfirm(state) {
    state.confirmDialog = null;
  },
  handleToggle(state, payload) {
    state.toggleShow[payload] = !state.toggleShow[payload];
    const check = Object.values(state.toggleShow).filter((e) => e);
    if (check.length === 0) {
      document.querySelector("html").style.overflowY = "scroll";
    } else {
      document.querySelector("html").style.overflowY = "hidden";
    }
  },
  handleCloseAllModal(state) {
    state.toggleShow = {};
    document.documentElement.style.overflowY = "scroll";
  },
  handleIsShowModal(state, payload) {
    state.isShowModal = payload;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
