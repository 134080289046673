import { SSRRequest} from "@/api/axios";

export const getBankInfoRequest = async () => {
    try{
        const res = await SSRRequest({
            url:'/xxxx_config/bank_info/get',
            method: 'GET',
        })
        return res
    }
    catch (error){
        console.log(error)
    }
}