<template>
  <div class="w-100 h-100 position-relative">
    <div
      @wheel.prevent
      v-if="isLoading"
      class="d-flex justify-center align-center live-casino w-100 h-100"
    >
      <div class="loading w-100 d-flex justify-center">
        <Loading />
      </div>
    </div>
    <div @wheel.prevent v-if="isLoading" class="w-100 h-100 live-opacity"></div>
    <router-view />
    <Toast />
  </div>
</template>

<script setup>
import Toast from "@/components/applecasino/Toast";
import Loading from "@/components/applecasino/Loading";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const isLoading = computed(() => store.state.auth.loading);
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrap {
  max-width: 1440px;
  margin: 0 auto;
}

.wrap-custom {
  max-width: 1920px;
  margin: 0 auto;
}

.live-opacity {
  opacity: 0.5;
  background-color: #1c2529;
  z-index: 88;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.live-casino {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

:root {
  --ready-casino-dark: #ab47bc;
  --ready-casino-light: #e75bff;

  --apple-casino-dark: #616161;
  --apple-casino-light: #8d8d8d;

  --royal-casino-primary: #349889;
  --royal-casino-gold: #b29233;

  --aqua-gold: #f2e79b;
  --aqua-dark: #3b9c93;
  --aqua-light: #4fd1c5;

  --royalty-dark: #0d0d0d;
  --royalty-light: #d00724;
  --royalty-primary-dark: #700010;
  --royalty-gray-light: #797b86;
  --royalty-red: #d43619;
}
</style>
