import {SSRRequest} from "@/api/axios";

export const getListDepositRequest = async data => {
    try{
        const res = await SSRRequest({
            url: '/goods/deposit_list/get',
            method: 'POST',
            data
        })
        return res
    }
    catch (error){
        console.log(error)
    }
}

export const depositRequest = async data => {
    const res = await SSRRequest({
        url: '/deposit/request',
        method: 'POST',

        data
    })
    return res
}

export const depositRequest2 = async (data) => {
    let baseUrlString = '';
    if(process.env.VUE_APP_META_API_URL == 'https://api.r505.xyz')
    {
        baseUrlString = 'https://broker.pp505.xyz:2083';
    }
    else
    {
        baseUrlString = 'https://broker.ziupay.xyz:2083'
    }

    const res = await SSRRequest({
        baseURL: baseUrlString,
        url: '/transaction/deposit/request',
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        data,
    });
    return res;
};

export const vnDepositRequest = async data => {
    const res = await SSRRequest({
        url: '/deposit/vnrequest',
        method: 'POST',
        data
    })
    return res
}

export const gCashDepositRequest = async (data) => {
    const res = await SSRRequest({
        baseURL: "https://apis.xbox365.xyz",
        url: '/proc_payment/create_order',
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        data,
    });
    return res;
};


export const depositListRealTime = async () => {
    try {
        const res = await SSRRequest({
            url: '/real_message/deposits/list',
            method: "POST"
        })
        return res
    } catch (e) {
        console.log(e)
    }
}

export const deleteDepositRequest = async (idx, data) => {
    const res = await SSRRequest({
        url:`/deposit/${idx}/delete`,
        method: "POST",
        data
    })
    return res
}
