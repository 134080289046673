import { getBankRequest, getBankRequest2, getGCashBankRequest, getVnBankRequest } from "@/api/getBank/request";
// import store from "@/store";
// import { computed } from "vue";

const state = {
    listBank: [],
    listBank2: [],
    gCashBank: []
};

const getters = {
    getBank2: (state) => {
        return state.listBank2
    },
    getBank: (state) => {
        // let grade = store.state.user.userInfor?.me_grade;
        // console.log(state.listBank.GRADE);
        // return state.listBank[0];
        // if(!grade) return store.state.partnership.data
        // switch (state.listBank) {
        //   case value:

        //     break;

        //   default:
        //     break;
        // }
        let bankVVIP = state.listBank.find((item) => item.GRADE === 4);
        let bankVIP = state.listBank.find((item) => item.GRADE === 3);
        let bankGeneral = state.listBank.find((item) => item.GRADE === 2);
        let bankNew = state.listBank.find((item) => item.GRADE === 1);

        if (bankVVIP) {
            return bankVVIP;
        }

        if (bankVIP) {
            return bankVIP;
        }

        if (bankGeneral) {
            return bankGeneral;
        }

        if (bankNew) {
            return bankNew;
        }
    },
};

const mutations = {
    handleUpdateBank(state, payload) {
        state.listBank = payload;
    },
    handleUpdateBank2(state, payload) {
        state.listBank2 = payload;
    },
    handleUpdateGCashBank(state, payload) {
        state.gCashBank = payload;
    },
};

const actions = {
    async getBankAction({ commit }) {
        const res = await getBankRequest();
        try {
            commit("handleUpdateBank", res?.data);
            commit("handleUpdateBank2", res?.data2);
        } catch (error) {
            console.log(error);
        }
    },
    async getBankAction2({ commit }, data) {
        const res = await getBankRequest2(data);
        try {
            commit("handleUpdateBank2", res?.data);
        } catch (error) {
            console.log(error);
        }
    },
    async getGCashBankAction({ commit }, data) {
        const res = await getGCashBankRequest(data);
        try {
            commit("handleUpdateGCashBankInfo", res?.body.data);
        } catch (error) {
            console.log(error);
        }
    },
    async getVnBankAction({ commit }, data) {
        const res = await getVnBankRequest(data);
        try {
            commit("handleUpdateVnBankInfo", res?.data.data);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
